<template>
  <l-content-widget
    :sticky="widget.isSticky"
    fullHeight
  >
    <template #widget>
      <l-widget fixed-height>
        <template #header>
          <w-timer
            :start="widget.timer.start"
            :end="widget.timer.end"
            :server-time="widget.timer.current"
          />
        </template>

        <template #default>
          <ol class="ol ol_type_widget d-none d-xl-block">
            <li
              v-for="(title, i) in widget.list"
              :key="i"
            >
              {{ title }}
            </li>
          </ol>

          <v-select
            v-model="widget.selectedUrl"
            :options="widget.list"
            :clearable="false"
            class="d-xl-none v-select_type_widget"
          />
        </template>

        <template #footer>
          <a class="text-color-primary text-bold" href="#">
            Инструкция
          </a>
        </template>
      </l-widget>
    </template>

    <template #default>
      <v-tabs>
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          :slot="`slot-${index}`"
          class="d-flex align-items-center justify-content-center"
        >
          <v-icon-svg
            :class="`v-icon-svg_mode_${index}`"
            view-box="0 0 20 20"
            width="20"
            height="20"
            class="mr-12"
          >
            <component :is="`SvgIconWa${tab.icon}`"/>
          </v-icon-svg>

          {{ tab.name }}
        </div>

        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          :label="tab.name"
          class="p-32"
        >
          <h1 class="text-color-text-secondary mb-24">
            {{ tab.name }}
          </h1>

          <h2 class="mb-24">
            Заголовок H2 (подзаголовок в тексте)
          </h2>

          <div
            ref="testContainer"
            v-html="htmlText"
            @mouseup="articleSelected"
          />

          <h4 class="mb-8">Сообщение в мессенджере</h4>

          <v-sheet
            rounded
            outlined
            class="text-size-14 mb-24"
          >
            <div class="p-12 bg-color-primary text-color-background-3">
              Документ Б: Переписка
            </div>

            <div class="container-fluid px-12">
              <div class="row align-items-center my-12">
                <div class="col-auto">
                  <v-icon-svg
                    fill="var(--color-text-secondary)"
                    view-box="0 0 16 16"
                    width="16"
                    height="16"
                  >
                    <svg-icon-wa-message-arrow/>
                  </v-icon-svg>
                </div>

                <div class="col-auto">
                  <v-avatar
                    size="40"
                    :img="avatarImg"
                  />
                </div>

                <div class="col">
                  <p class="m-0 text-bold">Макс Парр, директор по развитию бизнеса, Link Rall</p>
                  <p class="m-0">Online</p>
                </div>
                <div class="col-auto text-nowrap">
                  <v-icon-svg
                    fill="var(--color-text-secondary)"
                    view-box="0 0 24 24"
                    width="24"
                    height="24"
                    class="mr-24"
                  >
                    <svg-icon-wa-message-video/>
                  </v-icon-svg>

                  <v-icon-svg
                    fill="var(--color-text-secondary)"
                    view-box="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <svg-icon-wa-message-phone/>
                  </v-icon-svg>
                </div>
              </div>

              <v-divider/>

              <div class="row my-16">
                <div class="col-6">
                  <v-sheet
                    rounded
                    class="bg-color-background-2 p-12"
                  >
                    <div class="row align-items-end">
                      <div class="col">
                        <p>Уважаемый (Ваше имя),</p>
                        <p class="m-0">
                          Добро пожаловать в компанию Link Rall. Я надеюсь на плодотворное сотрудничество на благо
                          Компании и с удовольствием окажу Вам помощь.
                        </p>
                      </div>
                      <div class="col-auto">
                        <span class="text-color-text-secondary">12:22</span>
                      </div>
                    </div>
                  </v-sheet>
                </div>
              </div>

              <div class="row my-12">
                <div class="col">
                  <v-sheet
                    rounded="pill"
                    class="bg-color-background-1 px-16 py-12"
                  >
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <v-icon-svg
                          fill="var(--color-text-secondary)"
                          view-box="0 0 24 24"
                          width="24"
                          height="24"
                          style="top: 0"
                        >
                          <svg-icon-wa-message-smile/>
                        </v-icon-svg>
                      </div>

                      <div class="col">
                        <p class="text-color-text-secondary m-0">Введите сообщение</p>
                      </div>

                      <div class="col-auto">
                        <v-icon-svg
                          fill="var(--color-text-secondary)"
                          view-box="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <svg-icon-wa-message-attach/>
                        </v-icon-svg>
                      </div>
                    </div>
                  </v-sheet>
                </div>
                <div class="col-auto">
                  <v-sheet
                    rounded="circle"
                    class="bg-color-background-1 p-12"
                  >
                    <v-icon-svg
                      fill="var(--color-text-secondary)"
                      view-box="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <svg-icon-wa-message-mic/>
                    </v-icon-svg>
                  </v-sheet>
                </div>
              </div>
            </div>
          </v-sheet>

          <h3>Заголовок H3 (подзаголовок в тексте, для дополнительных названий)</h3>

          <p>
            Холдинг «Химпром» (далее - «Химпром», холдинг, компания) является крупнейшим холдингом в российской
            нефтехимической отрасли, работающим в нефтехимическом сегменте.
          </p>

          <p>
            «Химпрому» принадлежат три установки пиролиза, одна установка дегидрирования пропана, два завода по
            производству базовых полимеров, выпускающих полиэтилен и полипропилен, три завода по производству
            синтетических каучуков, выпускающих базовые и специальные каучуки и термоэластопласты, и 13 предприятий,
            производящих широкий ассортимент полуфабрикатов.
          </p>

          <h4>Заголовок 4 (для списков и названий)</h4>

          <ul class="ul ul_type_point">
            <li>
              Сохранение лидирующей позиции и постоянное увеличение доли на рынке во всех представленных в компании
              продуктовых сегментов;
            </li>
            <li>Предоставление потребителям продукции высокого качества</li>
          </ul>

          <h4 class="mb-8">Диаграмма (варианты)</h4>
          <div class="row">
            <div class="col">
              <div class="wa-image">
                <img class="wa-image__source" src="@assets/images/verstka/diagram-1.png" alt="">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="wa-image">
                <img class="wa-image__source" src="@assets/images/verstka/diagram-2.png" alt="">
              </div>
            </div>
          </div>

          <div class="row mb-40">
            <div class="col">
              <div class="wa-image">
                <img class="wa-image__source" src="@assets/images/verstka/diagram-3.png" alt="">
              </div>
            </div>
          </div>

          <h4 class="mb-8">Стратегическими целями холдинга являются:</h4>

          <ol class="ol ol_type_decimal">
            <li>
              Сохранение лидирующей позиции и постоянное увеличение доли на рынке во всех представленных в компании
              продуктовых сегментов:

              <ol>
                <li>Предоставление потребителям продукции высокого качества;</li>
                <li>
                  Предоставление потребителям продукции высокого качества:

                  <ol>
                    <li>
                      Сохранение лидирующей позиции и постоянное увеличение доли на рынке во всех представленных в
                      компании
                      продуктовых сегментов.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>Предоставление потребителям продукции высокого качества.</li>
          </ol>

          <v-divider class="my-40"/>

          <h1 class="text-color-text-secondary mb-24">Информация для составления плана мероприятий по работе с клиентами</h1>

          <h4 class="mb-8">Многоуровневый маркированный список</h4>

          <ul class="ul ul_type_point">
            <li>Годовой бюджет на проведение мероприятий – 500 000 руб.</li>
            <li>
              В Вашем подчинении работают 5 клиентских менеджеров. За каждым из них закреплено несколько клиентов. Все
              менеджеры имеют примерно равную квалификацию:

              <ul>
                <li>годовой бюджет на проведение мероприятий – 500 000 руб;</li>
                <li>
                  в Вашем подчинении работают 5 клиентских менеджеров. За каждым из них закреплено несколько клиентов.
                  Все
                  менеджеры имеют примерно равную квалификацию:

                  <ul>
                    <li>
                      в Вашем подчинении работают 5 клиентских менеджеров. За каждым из них закреплено несколько
                      клиентов.
                      Все
                      менеджеры имеют примерно равную квалификацию.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              В компании работает отдел маркетинга. Помимо регулярного мониторинга рынка, формирования прайсов,
              отслеживания трендов отрасли отдел также занимается организацией клиентских мероприятий. Вы, как
              руководитель отдела продаж, для достижения плановых показателей продаж и развития отношений с клиентами
              имеете полномочия обращаться в отдел маркетинга для организации мероприятий по Вашему запросу, исходя из
              собственного видения ситуации.
            </li>
            <li>
              В компании работает отдел маркетинга. Помимо регулярного мониторинга рынка, формирования прайсов,
              отслеживания трендов отрасли отдел также занимается организацией клиентских мероприятий.
            </li>
          </ul>

          <h4 class="mb-8">Письмо от руководителя:</h4>

          <v-sheet
            rounded
            outlined
            class="text-size-14 mb-24"
          >
            <div class="p-12 bg-color-primary text-color-background-3">
              Документ А: Письмо по электронной почте от вашего руководителя
            </div>

            <div class="container-fluid px-12">

              <div class="row mt-12">
                <div class="col">
                  <p class="mb-12"><span class="text-color-text-secondary">От:</span> <b>Макс Парр, директор по развитию
                    бизнеса, Link Rall</b></p>
                  <p class="mb-12"><span class="text-color-text-secondary">Кому:</span> <b>(Ваше имя), менеджер проектов, Link
                    Rall</b></p>
                  <p class="mb-12"><span class="text-color-text-secondary">Тема:</span> <b>Наш бизнес</b></p>
                  <p class="mb-12"><span class="text-color-text-secondary">Дата:</span> <b>4 апреля 20ХХ</b></p>
                </div>
              </div>

              <v-divider/>

              <div class="row my-16">
                <div class="col">
                  <v-sheet
                    rounded
                    class="bg-color-background-2 p-12"
                  >
                    <div class="row">
                      <div class="col">
                        <p>Уважаемый (Ваше имя),</p>
                        <p>
                          Добро пожаловать в компанию Link Rall. Я надеюсь на плодотворное сотрудничество на благо
                          Компании и с удовольствием окажу Вам помощь.
                        </p>
                        <p>
                          Я запланировал 30 минут для обсуждения. Я бы хотел, чтобы Вы начали эту встречу с
                          20-минутного обзора Ваших соображений, а затем я бы хотел перейти к обсуждению, в ходе
                          которого я планирую задавать вопросы, чтобы помочь Вам более четко сформулировать свою
                          позицию.
                        </p>

                        <v-divider class="my-16"/>

                        <p>До встречи,</p>
                        <p class="m-0">Макс Парр, директор по развитию бизнеса, Link Rall</p>
                      </div>
                    </div>
                  </v-sheet>
                </div>
              </div>
            </div>
          </v-sheet>

          <h4 class="mb-8">Таблица</h4>

          <v-table
            striped
            class="text-size-12 mb-16"
          >
            <template #thead>
              <v-table-tr class="bg-color-primary text-color-background-3">
                <v-table-th class="text-size-14 p-12">
                  Наименование товара
                </v-table-th>

                <v-table-th class="text-size-14 p-12">
                  Качественные характеристики
                </v-table-th>

                <v-table-th class="text-size-14 p-12">
                  Цена
                </v-table-th>
              </v-table-tr>
            </template>

            <template #default>
              <v-table-tr
                v-for="index of 4"
                :key="index"
                class="align-top"
              >
                <v-table-td>
                  Сахар белый кристалический категории TC2 ГОСТ 33222-2015 (Торговая марка «Сладкая жизнь»
                </v-table-td>

                <v-table-td>
                  Обладает свойственным сахару, сладким, без постороннего привкуса, как в сухом сахаре, так и в его
                  водном растворе, вкусом. Запах также не вызывает нареканий. Продукт белый, сыпучий, есть комки,
                  разваливающиеся при легком нажатии. Цветность, массовая доля редуцирующих веществ и микробиологические
                  показатели в пределах нормы.
                </v-table-td>

                <v-table-td class="text-nowrap">
                  32 руб/кг
                </v-table-td>
              </v-table-tr>
            </template>
          </v-table>

          <p class="mb-40">
            Коллеги отмечают ее как хорошего организатора, она умеет грамотно выстроить работу, несколько раз
            участвовала в реализации срочных проектов. При этом она все всегда успевала вовремя, даже если приходилось
            много перерабатывать. Зачастую ее результат зависел от работы других сотрудников, и она умудрялась
            «заставить» их выполнить нужные задачи, правда, не всегда это было в конструктивной манере. Несколько раз вы
            были свидетелем напряженного разговора между Натальей и ее коллегами, которые, на ее взгляд, недостаточно
            ответственно подходят к своей работе. В частности, буквально на днях она в очень резких тонах общалась с
            сотрудником отдела технического контроля Иваном Колядой. Вы не знаете подробностей ситуации, но считаете
            такой стиль общения неприемлемым.
          </p>

          <h4 class="mb-8">Фото и рисунки</h4>
          <div class="row">
            <div class="col">
              <div class="wa-image">
                <img class="wa-image__source" src="@assets/images/verstka/image-236.jpg" alt="ракета летит">
                <p class="wa-image__desc">Картинка 1</p>
              </div>
            </div>
          </div>

          <div class="row mb-40">
            <div class="col-12 col-md">
              <div class="wa-image">
                <img class="wa-image__source" src="@assets/images/verstka/image-234.jpg" alt="Энштейн">
                <p class="wa-image__desc">Картинка 2</p>
              </div>
            </div>

            <div class="col-12 col-md">
              <div class="wa-image">
                <img class="wa-image__source" src="@assets/images/verstka/image-235.jpg" alt="Гагарин">
                <p class="wa-image__desc">Картинка 3</p>
              </div>
            </div>
          </div>

          <h4 class="mb-8">Аудио</h4>
          <div class="row  mb-40">
            <div class="col">
              <audio-player :file="testAudio"/>
            </div>
          </div>

          <h4 class="mb-8">Видео (увеличивается при нажатии)</h4>
          <div class="row  mb-40">
            <div class="col">
              <video-player
                rounded
                :source="video.source"
              />
            </div>
          </div>

          <div class="wa-article">
            <div class="row align-items-center mb-8">
              <div class="col">
                <h4>Статья</h4>
              </div>
              <div class="col-auto">
                <v-menu :popperOptions="{ placement: 'bottom-end' }">
                  <template #button>
                    <v-button
                      link
                      @click="showHint = !showHint"
                      class="text-size-12 font-weight-normal"
                    >
                      <v-icon-svg
                        fill="var(--color-primary)"
                        view-box="0 0 12 12"
                        width="12"
                        height="12"
                        class="mr-8"
                      >
                        <svg-icon-wa-article-pin/>
                      </v-icon-svg>
                      Заметки секретаря
                    </v-button>
                  </template>

                  <div
                    class="p-12 text-size-14 bg-color-background-5"
                    style="min-width: 20rem; max-width: 40rem;"
                  >
                    <p>
                      Уважаемый (Ваше имя),
                      Эта статья может быть Вам полезна в вашей задаче. Руководство просило передать Вам ее.
                    </p>

                    <p class="text-color-text-secondary mb-0">
                      Секретарь
                    </p>
                  </div>

                </v-menu>

              </div>
            </div>

            <div class="row">
              <div class="col">
                <v-sheet
                  outlined
                  rounded
                  class="p-16"
                >
                  <div class="row">
                    <div class="col-12 col-md-6 col-lg-4">
                      <img
                        :src="articleImg1"
                        alt=""
                        class="mb-16"
                      >

                      <p>
                        Значимость этих проблем настолько очевидна, что укрепление и развитие структуры требуют от нас
                        анализа системы обучения кадров, соответствует насущным потребностям. С другой стороны
                        консультация
                        с широким активом позволяет оценить значение модели развития. Задача организации, в особенности
                        же
                        постоянный количественный рост и сфера нашей активности представляет собой интересный
                        эксперимент
                        проверки систем массового участия. Не следует, однако забывать, что постоянный количественный
                        рост
                        и
                        сфера нашей активности позволяет выполнять важные задания по разработке модели развития. С
                        другой
                        стороны постоянный количественный рост и сфера нашей активности позволяет оценить значение
                        системы
                        обучения кадров, соответствует насущным потребностям.
                      </p>
                    </div>

                    <div class="col-12 col-md-6 col-lg-8">
                      <div class="row">
                        <div class="col">
                          <span class="wa-article__text-secondary">Общественный круг почета</span>
                        </div>

                        <div class="col-auto text-right wa-article__text-secondary">
                          14.12.2019
                        </div>
                      </div>

                      <hr>

                      <div class="row">
                        <div class="col">
                          <h3>На рубеже веков: какие проблемы у молодежной сборной по хоккею</h3>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col text-right wa-article__text-secondary">
                          Курбанов А.
                        </div>
                      </div>

                      <hr>

                      <div class="row mt-16">
                        <div class="col-12 col-lg-6">
                          <p>
                            С другой стороны начало повседневной работы по формированию позиции играет важную роль в
                            формировании форм развития. Не следует, однако забывать, что постоянный количественный рост
                            и сфера нашей активности требуют определения и уточнения существенных финансовых и
                            административных условий.
                          </p>
                          <p>
                            Таким образом рамки и место обучения кадров требуют от нас анализа систем массового участия.
                            Задача организации, в особенности же начало повседневной работы по формированию позиции
                            требуют от нас анализа системы обучения кадров, соответствует насущным потребностям. Таким
                            образом реализация намеченных плановых заданий играет важную роль в формировании дальнейших
                            направлений развития. Идейные соображения высшего порядка, а также дальнейшее развитие
                            различных форм деятельности играет важную роль в формировании модели развития.
                          </p>
                        </div>

                        <div class="col-12 col-lg-6">
                          <p>
                            Значимость этих проблем настолько очевидна, что укрепление и развитие структуры требуют от
                            нас
                            анализа системы обучения кадров, соответствует насущным потребностям. С другой стороны
                            консультация с широким активом позволяет оценить значение модели развития.
                          </p>

                          <blockquote>
                            <p>
                              Значимость этих проблем настолько очевидна, что укрепление и развитие структуры требуют от
                              нас
                              анализа системы обучения кадров, соответствует насущным потребностям.
                            </p>
                          </blockquote>

                          <p>
                            Идейные соображения высшего порядка, а также постоянное обеспечение нашей деятельности
                            способствует подготовки и реализации существенных финансовых и административных условий. Не
                            следует, однако забывать, что сложившаяся структура организации в значительной степени
                            обуславливает создание существенных финансовых и административных условий.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-sheet>
              </div>
            </div>
          </div>
        </v-tab>
      </v-tabs>

      <v-snackbar
        v-model="snackbar.isShow"
        position="right"
        endless
      >
        <v-button
          :ripple="false"
          color="default"
          outlined
          size="sm"
          @click="modal.isShow = true"
          class="w-100"
        >
          Посмотреть сообщение
        </v-button>
      </v-snackbar>

      <v-modal v-model="modal.isShow">
        <v-sheet
          rounded
          outlined
          class="text-size-14"
        >
          <div class="p-12 bg-color-primary text-color-background-3">
            Документ Б: Переписка
          </div>

          <div class="container-fluid px-12">
            <div class="row align-items-center my-12">
              <div class="col-auto">
                <v-icon-svg
                  fill="var(--color-text-secondary)"
                  view-box="0 0 16 16"
                  width="16"
                  height="16"
                >
                  <svg-icon-wa-message-arrow/>
                </v-icon-svg>
              </div>

              <div class="col-auto">
                <v-avatar
                  size="40"
                  :img="avatarImg"
                />
              </div>

              <div class="col">
                <p class="m-0 text-bold">Макс Парр, директор по развитию бизнеса, Link Rall</p>
                <p class="m-0">Online</p>
              </div>
              <div class="col-auto text-nowrap">
                <v-icon-svg
                  fill="var(--color-text-secondary)"
                  view-box="0 0 24 24"
                  width="24"
                  height="24"
                  class="mr-24"
                >
                  <svg-icon-wa-message-video/>
                </v-icon-svg>

                <v-icon-svg
                  fill="var(--color-text-secondary)"
                  view-box="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <svg-icon-wa-message-phone/>
                </v-icon-svg>
              </div>
            </div>

            <v-divider/>

            <div class="row my-16">
              <div class="col-6">
                <v-sheet
                  rounded
                  class="bg-color-background-2 p-12"
                >
                  <div class="row align-items-end">
                    <div class="col">
                      <p>Уважаемый (Ваше имя),</p>
                      <p class="m-0">
                        Добро пожаловать в компанию Link Rall. Я надеюсь на плодотворное сотрудничество на благо
                        Компании и с удовольствием окажу Вам помощь.
                      </p>
                    </div>
                    <div class="col-auto">
                      <span class="text-color-text-secondary">12:22</span>
                    </div>
                  </div>
                </v-sheet>
              </div>
            </div>

            <div class="row my-12">
              <div class="col">
                <v-sheet
                  rounded="pill"
                  class="bg-color-background-1 px-16 py-12"
                >
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <v-icon-svg
                        fill="var(--color-text-secondary)"
                        view-box="0 0 24 24"
                        width="24"
                        height="24"
                        style="top: 0"
                      >
                        <svg-icon-wa-message-smile/>
                      </v-icon-svg>
                    </div>

                    <div class="col">
                      <p class="text-color-text-secondary m-0">Введите сообщение</p>
                    </div>

                    <div class="col-auto">
                      <v-icon-svg
                        fill="var(--color-text-secondary)"
                        view-box="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <svg-icon-wa-message-attach/>
                      </v-icon-svg>
                    </div>
                  </div>
                </v-sheet>
              </div>
              <div class="col-auto">
                <v-sheet
                  rounded="circle"
                  class="bg-color-background-1 p-12"
                >
                  <v-icon-svg
                    fill="var(--color-text-secondary)"
                    view-box="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <svg-icon-wa-message-mic/>
                  </v-icon-svg>
                </v-sheet>
              </div>
            </div>
          </div>
        </v-sheet>

        <v-button
          slot="footer"
          @click="modal.isShow = false"
        >
          Вернуться к тексту
        </v-button>
      </v-modal>
    </template>
  </l-content-widget>
</template>

<script>

import avatarImg from '@assets/images/verstka/avatar44x44.png'
import articleImg1 from '@assets/images/verstka/article-img-1.jpg'
import testAudio from '@assets/audio/test.mp3'
import VSelect from 'vue-select'
import { LContentWidget, LWidget } from '@/layouts'
import {
  VAvatar,
  VDivider,
  VIconSvg,
  VMenu,
  VModal,
  VSheet,
  VSnackbar,
  VTab, VTable, VTableTd, VTableTh, VTableTr,
  VTabs
} from '@components/base'
import { AudioPlayer, VideoPlayer } from '@components/features'
import { WTimer } from '@components/widgets'
import SvgIconWaAnswerField from '@components/icons/WA/SvgIconWaAnswerField'
import SvgIconWaArticlePin from '@components/icons/WA/article/SvgIconWaArticlePin'
import SvgIconWaMessageArrow from '@components/icons/WA/message/SvgIconWaMessageArrow'
import SvgIconWaMessageAttach from '@components/icons/WA/message/SvgIconWaMessageAttach'
import SvgIconWaMessageMic from '@components/icons/WA/message/SvgIconWaMessageMic'
import SvgIconWaMessagePhone from '@components/icons/WA/message/SvgIconWaMessagePhone'
import SvgIconWaMessageSmile from '@components/icons/WA/message/SvgIconWaMessageSmile'
import SvgIconWaMessageVideo from '@components/icons/WA/message/SvgIconWaMessageVideo'
import SvgIconWaTask from '@components/icons/WA/SvgIconWaTask'
import SvgIconWaVideo from '@components/icons/WA/SvgIconWaVideo'
import VButton from '@components/base/VButton/VButton'

export default {
  name: 'SWaTasks',

  components: {
    VModal,
    AudioPlayer,
    LContentWidget,
    LWidget,
    SvgIconWaAnswerField,
    SvgIconWaArticlePin,
    SvgIconWaMessageArrow,
    SvgIconWaMessageAttach,
    SvgIconWaMessageMic,
    SvgIconWaMessagePhone,
    SvgIconWaMessageSmile,
    SvgIconWaMessageVideo,
    SvgIconWaTask,
    SvgIconWaVideo,
    VAvatar,
    VButton,
    VDivider,
    VIconSvg,
    VMenu,
    VSelect,
    VSheet,
    VSnackbar,
    VTab,
    VTable,
    VTableTd,
    VTableTh,
    VTableTr,
    VTabs,
    VideoPlayer,
    WTimer
  },

  data () {
    return {
      articleImg1,
      testAudio,
      avatarImg,
      modal: {
        isShow: false
      },
      snackbar: {
        isShow: true
      },
      showHint: true,
      widget: {
        isSticky: window.innerWidth > 768,
        selectedUrl: 'Вводная информация для участника',
        timer: {
          start: Math.floor(+new Date() / 1000),
          end: Math.floor(+new Date() / 1000) + 60 * 20
        },
        list: [
          'Вводная информация для участника',
          'Краткая справка',
          'Материалы для участника. Обзор текущего состояния дорожной отрасли',
          'Рынок геосинтетики',
          'Южный регион',
          'Завод «Новые дороги»',
          'Доп. ссылка 1',
          'Доп. ссылка 2',
          'Доп. ссылка 3',
          'Доп. ссылка 4',
          'Доп. ссылка 5',
          'Доп. ссылка 6',
          'Доп. ссылка 7'
        ]
      },
      htmlText: '<p>Холдинг <strong>«Химпром»</strong> (далее - «Химпром», холдинг, компания) <i>является крупнейшим холдингом</i> в российской нефтехимической отрасли, работающим в нефтехимическом сегменте. В 2017 году его выручка составила 53 млрд. рублей. В состав входит ряд производственных и торговых предприятий по всей территории России. Компания производит и реализует свою продукцию как на отечественном, так и на зарубежном рынке, является надежнымпартнером крупнейших заказчиков.</p><p>«Химпрому» принадлежат три установки пиролиза, одна установка дегидрирования пропана, два завода по производству базовых полимеров, выпускающих полиэтилен и полипропилен, три завода по производству синтетических каучуков, выпускающих базовые и специальные каучуки и термоэластопласты, и 13 предприятий, производящих широкий ассортимент полуфабрикатов, пластиков и продуктов органического синтеза, в том числе полиэтилентерефталат, гликоли, спирты, БОПП-пленки, вспенивающийся полистирол и акрилаты.</p>',
      tabs: [
        {
          name: 'Задание',
          icon: 'Task'
        },
        {
          name: 'Поле ответа',
          icon: 'AnswerField'
        },
        {
          name: 'Видео-связь',
          icon: 'Video'
        }
      ],
      video: {
        source: {
          poster: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.jpg',
          type: 'video',
          sources: [
            {
              src: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4',
              type: 'video/mp4',
              size: 576
            },
            {
              src: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-720p.mp4',
              type: 'video/mp4',
              size: 720
            },
            {
              src: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-1080p.mp4',
              type: 'video/mp4',
              size: 1080
            }
          ]
        }
      }
    }
  },

  methods: {
    articleSelected () {
      const container = this.$refs.testContainer[0]
      const sel = window.getSelection()
      const range = sel.getRangeAt(0)
      const selStart = range.startOffset
      const selEnd = range.endOffset
      const charsBeforeStart = getCharactersCountUntilNode(range.startContainer, container)
      const charsBeforeEnd = getCharactersCountUntilNode(range.endContainer, container)
      const startIndex = charsBeforeStart + selStart
      const endIndex = charsBeforeEnd + selEnd

      if (charsBeforeStart < 0 || charsBeforeEnd < 0) {
        console.warn('out of range')
        return
      }

      console.log('start index', startIndex)
      console.log('end index', endIndex)

      // const textStart = this.htmlText.slice(0, startIndex)
      // const textHighlight = this.htmlText.slice(startIndex, endIndex)
      // const textEnd = this.htmlText.slice(endIndex, this.htmlText.length - 1)
      // // text = text.splice(endIndex, 0, '</span>')
      // // text = text.splice(startIndex, 0, '<span class="text-color-danger">')
      //
      // console.log(1, textStart)
      // console.log(2, textHighlight)
      // console.log(3, textEnd)

      // this.htmlText = `${textStart}<span class="text-color-danger">${textHighlight}</span>${textEnd}`

      function getCharactersCountUntilNode (node, parent) {
        const walker = document.createTreeWalker(
          parent || document.body,
          NodeFilter.SHOW_TEXT,
          null,
          false
        )
        let found = false
        let chars = 0
        while (walker.nextNode()) {
          if (walker.currentNode === node) {
            found = true
            break
          }
          chars += walker.currentNode.textContent.length
        }
        if (found) {
          return chars
        } else return -1
      }
    }
  }
}
</script>

<style lang="scss">
.v-icon-svg {
  &_mode {
    &_0 {
      top: 0
    }

    &_1 {
      top: 1px
    }
  }
}
</style>
